<template>
    <button
        ref="yellowButton"
        type="submit"
        class="block w-[298px] font-primary tracking-wider whitespace-nowrap select-none border-none lg:w-[468px]"
        :class="{
            'cursor-not-allowed': disabled,
            'grayscale': disabled || pseudoDisabled,
            'cursor-pointer': !disabled && !pseudoDisabled,
            'w-[298px] lg:w-[468px]': big,
            'w-[298px] lg:w-[380px]': !big,
            'relative': !floating,
            'floating-button fixed left-1/2 -translate-x-1/2 bottom-5': floating,
        }"
    >
        <img
            v-if="disabled || pseudoDisabled"
            src="/images/button-bg-disabled.png"
        >
        <img
            v-else-if="outlined"
            src="/images/button-bg-outlined.png"
        >
        <img
            v-else
            src="/images/button-bg.png"
        >

        <span
            v-html="text"
            class="absolute top-1/2 left-1/2 whitespace-pre"
            :class="{
                'text-red-100': !whiteText && !yellowText,
                'text-white': whiteText && !yellowText,
                'text-yellow': yellowText,
                'text-16 lg:text-26': big,
                'text-16 lg:text-20': !big,
            }"
            style="transform: translate(-50%, -50%)"
        ></span>

        <img
            v-if="withHearts"
            src="/images/heart-1.svg"
            class="absolute top-[25%] left-[11%] max-w-[28px] lg:max-w-none"
        >

        <img
            v-if="withHearts"
            src="/images/heart-2.svg"
            class="absolute top-[0] right-[0] max-w-[38px] lg:max-w-none"
        >

        <!-- For pre-loading -->
        <img src="/images/button-bg-disabled.png" class="hidden">
        <img src="/images/button-bg-outlined.png" class="hidden">
        <img src="/images/button-bg.png" class="hidden">
    </button>
</template>

<script setup>
    import Navigator from '~/src/Navigator';

    const emit = defineEmits(['submit']);

    const props = defineProps({
        handleClick: {
            type: Boolean,
            default: true,
        },
        url: {
            type: String,
            required: false,
        },
        text: {
            type: String,
            required: true,
        },
        withHearts: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        pseudoDisabled: {
            type: Boolean,
            default: false,
        },
        big: {
            type: Boolean,
            default: false,
        },
        whiteText: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        yellowText: {
            type: Boolean,
            default: false,
        },
        floating: {
            type: Boolean,
            default: false,
        },
    });

    const yellowButton = ref(null);

    onMounted(() => {
        if (props.handleClick) {
            yellowButton.value.addEventListener('click', onClick);
        }
    });

    const onClick = (e) => {
        e.preventDefault();

        if (props.disabled) {
            console.log('Button is disabled');

            return;
        }

        if (props.url) {
            // navigateTo(props.url);
            Navigator.goto(props.url);

            return;
        }

        emit('submit');
    };
</script>
